#analytics {
  background-color: var(--color-background);
  width: 100% !important;
  height: 90vh !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 40px ;
}

.analytics-home{
  width: 70%;
  height: 90vh;
  overflow-y: auto;
}
.analytics-filter {
  width: 30%;
  height: 90vh;
  border-left: 1px solid #BDBDBD;
  overflow-y: auto;
}

.analytics-filter-search{
  padding-left: 20px;
}

.carousel-reports{
  width: 100%;
  z-index: 2;
  background-color: #fff;
}

.report-tag{
  background-color: var(--color-primary-light) !important;
  padding: 8px!important;
  border-radius: 16px !important;
  font-size: 1.4rem !important;
  display: flex !important;
  align-items: center;
  border:1px #f2f2f2 solid !important;
  cursor:  pointer !important;
  
}

.selected-report{
  border:2px black solid !important;

}

.report-tag:hover{

}

.tags-div{
}

.reports-list-row{
  display: flex;
margin: auto;
justify-content: flex-start;
width: 90%;
background-color: #fff;
height: 80px;
position: fixed;
top: 1;
z-index: 10;
border-bottom: 1px solid #f2f2f2;
}

.analytics-filter-details-name{
  padding-top: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1D3948;
  line-height: 20px;
}

.analytics-filter-details-email{
  font-size: 1.2rem;
  color: #1D3948;
  line-height: 16px;
}


.analytics-dropdown{
  color:#000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 20px;
}

.timeline-scroll{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end ;
  align-items: flex-end;
  padding-right: 20px;
}

.analytics-overview{
  display: flex;
  flex-direction: row;
}
.analytics-response{
  width: 70%;
}

.analytics-teammates{
  width: 30%;
  border-left: 0.5px solid #AFAFAF;
}
.analytics-teammates-header{
  font-size: 1.5rem;
  columns: #1D3948;
  font-weight: bold;
  padding-left: 14px;
}

.analytics-teammates-title{
  font-size: 1.3rem;
}

.analytics-teammates-star{
  color: #1D3948;
  width: 13px;
  height: 13px;
  margin-right: 7px;
}

.report-view{
  margin-top: 70px;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;