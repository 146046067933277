#profile-root {
  background-color: var(--color-background);
  width: 100% !important;
  min-height: 90vh !important;
  display: flex;
  flex-direction: column;
}

.profile-top-shadow{
  min-height: 35vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 100px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-name{
  color: #323435;
  font-weight: bold;
  font-size: 1.6rem;
  margin-top: 10px;
}


.profile-role{
  color: #323435;
  font-size: 1.3rem;
  margin-top: 3px;
}

.profile-avatar{
  width: 120px !important;
  height: 120px !important;
}

.profile-inputs{
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  height: 40vh;
  overflow-y: scroll;

  @media (max-width: 961px) {
    margin-top: 35px;
    height: 50vh;
  }
}

.profile-input{
  background-color: #FAFAFA !important; 
  height: 49px !important;
  border: none !important;
  margin-top: 13px !important;
  color: #989898 !important;
}

.profile-label{
  margin-bottom: 6px;
  font-weight: bold;
}

.input-div{
  margin-left: 15px;

  @media (max-width: 961px) {
    padding-left: 33px;
    padding-right: 33px;
    margin-left: 0;
    margin-top: 25px;
    width: 100% !important;
  }
}

.input-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 45px;

  @media (max-width: 961px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 19px;
  }
}

.right-align{
  justify-content: flex-start;
}

.input-row-right{
  width: 90%;
  display: flex;
}



@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;