.custom-spin .spinner-icon {
    font-size: 35px !important;
  }

 .ant-spin{
    color: #000 !important;
  }

  .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    margin-top: 20px;
  }
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;