#reports {
  background-color: var(--color-background);
  width: 100% !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 961px) {
    display: block;
  }
}

.report-page-full {
  height: 90vh !important;
  padding: 40px;

}

.opening-msg {

  @media (max-width: 961px) {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.question {

  @media (max-width: 961px) {
    padding: 16px 0 !important;
  }
}

.question-title {

  @media (max-width: 961px) {
    font-size: 1.2rem;
  }
}

.cb-question .ant-checkbox-group-item {

  @media (max-width: 961px) {
    margin-top: 5px;
  }
}

.flex-response {
  display: flex;
  // height: 46vh;
  overflow-x: auto;
  flex-direction: column;
}

.flex-headers {
  display: flex;
}

.rev-head {
  width: 50%;
}

.compare-review {
  margin: 50px auto;
  width: 90%;
}

.list-flex {
  display: flex;
}

.view-btns-col {
  display: flex;
  margin: 5px 0 5px 10px;
  // flex-direction: column;
}

.report-card {
  display: flex;
  align-items: center;
}

.text-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  border-radius: 12px;
  width: 70%;
  min-height: 130px;
  margin: 20px 0;
  padding: 15px;
}

.larger {
  font-weight: bolder;
  font-size: 1.8rem;
}

.text-response {
  margin-top: 10px;
  font-size: 1.4rem;
}

.rate-contain {
  padding: 5px !important;
  margin-right: 15px;
  width: 30%;
  display: flex;
  align-items: center;

}
.del-rate-contain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  width:70%

}

.del-list-flex{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
}

.rating-title{
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.del-rating-response{
  background-color: #BFECE6;
  display: flex;
  flex-direction: column;
  width:70%;
  border-radius: 20px;
  padding: 15px;
  margin: 10px 0px;
}

.del-resp{
    border-bottom: 1px solid #000;
    margin: 10px 0px;
  }

.printable {
  width: 40%;
}

.rate-vatar {
  width: 50px !important;
  height: 50px !important;
  margin-right: 10px !important;
}

.red-rating .ant-rate-star {
  color: #ff4d4f !important;
  font-size: 3rem !important;
}

.green-rating .ant-rate-star {
  color: #304D12 !important;
  font-size: 3.3rem !important;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;