.custom-sider {
  display: none !important;
  background-color: var(--color-primary-light) !important;
  padding-top: 2rem;
}

.custom-sider .logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.bottom-item {
  position: absolute !important;
  bottom: 85px !important;
  width: 100% !important;
  //  display: flex !important;
  align-items: center;
  background-color: var(--color-primary-light) !important;

}

.bottom-item:hover {
  background-color: white !important;
}

.menu-logo {
  // position: absolute;

}

.log-out {
  // position: absolute;
}

@media (min-width: 992px) {
  .custom-sider {
    display: inline-block !important;
  }
}

.dropdown-sidemenu {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 600;
}

.dropdown-sidemenu-item {
  display: flex;
  align-items: center;
}

.dropdown-sidemenu-text {
  margin-left: 16px;
}

.dropdown-sidemenu-logo {
  width: 32px;
}

.employee-active-tag.ant-tag {
  background-color: var(--color-text-brown);
  color: #fff;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;