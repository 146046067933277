.search {
    background-color: #F5F5F5 !important;
    margin: 5px 0 10px 0;
    border-radius: 6px !important;
}

.search input {
    background-color: #F5F5F5;
}

.upload-button{
    color: #fff !important;
    background-color: var(--color-text-brown) !important;
}

.upload-button:hover{
    background-color: var(--color-text-base) !important;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;