@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

:root {
  --color-background: #ffffff;
  --color-primary-lighter: #d7c499;
  --color-primary-light: #F8FAFB;
  --color-primary: #1D3948;
  --color-primary-dark: #1D394883;
  --color-secondary: #F8FAFB;
  --color-secondary-dark: #1D3948;
  --color-title-in-primary: #ffffff;
  --color-text-in-primary: #3c4a56;
  --color-text-title: #32264d;
  --color-text-complement: #9c98a6;
  --color-text-base: #396a85;
  --color-text-brown: #1E7D79;
  --color-line-in-white: #e6e6f0;
  --color-input-background: #f8f8fc;
  --color-button-text: #ffffff;
  --color-box-base: #0a0101;
  --color-box-footer: #fafafc;

  font-size: 60%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--color-background);
}

body {
  background-color: var(--color-background);
}

body,
input,
button,
textarea {
  font: 500 1.6rem 'Open Sans';
  color: var(--color-text-in-primary);
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}

@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;