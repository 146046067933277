.navbar-mob {
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  position: fixed;
  width: 100vw;
  z-index: 9999;
}

ul > li {
  font-size: 16px;
}

// .ant-drawer-body {
//   padding: 20% 13px !important;
// }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: var(--color-primary-lighter);
}
.ant-menu-item-selected {
  background-color: white !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #1D3948 !important;
}

.ant-menu-item a:hover {
  color: #1D3948 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0 !important;
}

.notif-icon{
  margin: 0 0 0 15px;
  width: 25px;
  cursor: pointer;
}

.ant-menu-item-active{
  // color: var(--color-primary-lighter) !important;
  background-color: #fff;
}

.ant-menu{
  background-color: transparent !important;
}

.ant-menu-item{
  color: #1D3948 !important;
}
.ant-menu-item a{
  color: #1D3948 !important;
}


.activeLink{
  
}

.navbar-main {
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 5.5rem;
  // position: fixed;
  width: 100%;
  // z-index: 9999;
}

.navbar-main .user-info-wrapper {
  display: flex;
  align-items: center;
  border-right: 1px solid #eff4f7;
  padding: 0 2rem;
}

.navbar-main .user-name {
  line-height: 1rem;
  color: var(--color-text-in-primary);
  margin-right: 2rem;
}

.user-name h5 {
  font-weight: 500;
}

.user-name .role {
  color: var(--color-text-brown);
}

.usertype-select{
  margin-top: 3px !important;
  min-width: 115px;
}

.activeLink:hover{
  color: #000 !important;
}

.menu-icon{
  font-size: 2.2rem;
}

 .ant-popover-content{
  width: 450px !important;
}

.ant-popover-arrow{
  left: 85% !important;
}

.menu-trigger{
  font-size: 3rem;
  margin: 15px 0px 0px 15px;
  cursor: pointer;
}

.notification-review-comment {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .navbar-mob {
    display: none;
  }
  .navbar-main {
    display: flex;
  }
}

@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;