#team-root {
  background-color: var(--color-background);
  width: 100% !important;
  min-height: 90vh !important;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
}

.team-tables {
  height: 75vh;
  overflow-y: scroll;
}

.team-table-label {
  color: var(--color-primary);
  margin: 20px auto;
  font-weight: bold;
}

.team-mobile-header {
  display: block;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 33px;
  color: #1D3948;
  padding-bottom: 18px;
  margin: 20px 24px;
  border-bottom: 0.5px solid #D5D5D5;
}

.mobile-teams-table-row {
  background-color: #F8FAFB;
  height: 100px;
}

.mobile-teams-table-data-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
}

.mobile-teams-table-name {
  color: var(--color-primary);
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 16px;
}

.mobile-teams-table-role {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 1.0rem;
}

.mobile-teams-table-details-title {
  display: inline-block;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 1.0rem;
  width: 66px;
  overflow-wrap: break-word;
}

.mobile-teams-table-details-data {
  padding-left: 11px;
  color: var(--color-primary);
  font-size: 1.0rem;
}


.td-mobile {
  margin-bottom: 20px;
  padding: 0 0 16px 0px !important;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;