.name {
    margin: 5px;
}


.modal-avatar {
    margin: 0 auto 30px auto !important;
    width: 120px !important;
    height: 120px !important;
}

.welcome-text {
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
    color: #1D3948;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.profile-avatar{
    border-radius: 50%;
    margin-bottom: 2px;
    height: 135px;
    margin: 35px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat:no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: aquamarine;
    margin: auto;
}

.logo-label{
    text-align: center;
    font-size: 1.5rem;
    font-weight:bold;
    margin-top: 10px;
}

.profilepic-edit{
    height: 40px;
    padding: 0px 0px 0px 0px;
    background-color: #fff;
    font-size: 2rem;
    width: 40px;
}

.form-item{
    margin-bottom: 10px !important;
    color:#1f4e99
}

.welcome-options {
    text-align: center;
    margin: 0 auto 20px auto;

}

.logo{
    width:50px;
}

.welcome-options-btn {
    margin-left: 16px;
    margin-right: 16px;
}

.welcome-email {
    color: #1D3948;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-modal .ant-modal-content {
    width: 700px;
}

.avatar-flex {
    display: flex;
    justify-content: center;
}

.check {
    color: green;
    font-size: 1.7rem;
    margin-right: 1px;
}

.color-logo {
    margin: 0px auto 20px auto;
    display: flex;
}

.cancel-btn {}

.reg-btn {
    background-color: var(--color-text-brown) !important;
}

.reg-btn:hover {
    background-color: var(--color-text-base) !important;
}

.data-input-modal .ant-modal-header {
    background-color: var(--color-text-brown) !important;
    color: #fff !important;

}

.data-input-modal .ant-modal-title {
    color: #fff !important;

}

.data-input-modal .ant-modal-close {
    color: #fff !important;
}

.modal-data-input {
    margin: 7px 0px !important;
}

.modal-select {
    width: 100%;
    margin: 7px 0px !important;
}

.employee-info {
    display: flex;
    align-items: center;
    margin: 50px 0px 0px 15px;
}

.drawer-avatar {
    margin: 0px 15px 0 0 !important;
    width: 50px !important;
    height: 50px !important;
}

.drawer-emp-details {
    display: flex;
    flex-direction: column;
    color: var(--color-primary) !important;
}

.drawer-emp-name {
    font-size: 1.5rem;
    font-weight: bold;
}

.drawer-line {
    margin: 40px auto;

    border: 1px solid #D6D6D6;
}

.employee-drawer .ant-drawer.ant-drawer-open .ant-drawer-mask {
    opacity: 0 !important;

}

.employee-drawer .ant-drawer-mask {
    opacity: 0 !important;

}

.drawer-tab .ant-tabs-tab {
    width: 50%;
    justify-content: center;
}

.drawer-tab .ant-tabs-nav-list {
    width: 100%;

}

.drawer-tab .ant-tabs-tab:hover {
    color: var(--color-primary-dark) !important;
}

.drawer-tab>.ant-tabs-nav::before {

    border-bottom: 5px solid #f0f0f0 !important;
}


.drawer-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-primary) !important;
    font-weight: 500;
}

.drawer-tab .ant-tabs-ink-bar {
    position: absolute;
    background: var(--color-primary) !important;
    pointer-events: none;
    height: 5px !important;
}

.drawer-tabs {
    width: 100%;
    color: var(--color-primary) !important;
}

.founder-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    font-weight: 500;
}

.ant-tabs-tab:hover {
    color: #000;
}

.founder-tabs .ant-tabs-nav-list {
    margin-left: 20px;
}

.manager-container {
    width: 350px;
    padding: 15px;
    border: solid #000 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.manager-avatar {
    width: 50px !important;
    height: 50px !important;
}

.manager-name {
    margin: 2px 0 2px 0;
    display: flex;
    justify-content: flex-start;
}

.label {
    min-width: 90px;
    font-weight: bold;
}

.manager-contain-right {
    margin-left: 20px;
}

.quest-list-item {
    display: flex !important;
    justify-content: space-between !important;
}

// .ant-drawer-body {
//   padding: 20% 13px !important;
// }

.contain-button-and-label{
    display: block;
}

.select-input{
    width: 100%;
    margin: 7px 0px !important
}

.contain-excel-modal-header{
    font-size: 5;
    font-weight: bold;
    margin-bottom: 20px;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;