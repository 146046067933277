#user-mgmt-root{
    background-color: var(--color-background);
    width: 100% !important;
    min-height: 90vh !important;
    // max-height: 90vh !important;
    display: flex;
    // justify-content: center;
}

.left-nav{
    border-right: 1px solid #afafaf;
    margin-right: 40px;
    width: 20%;
    // text-align: center;
}

.right{
    width: 80%;
}

.nav-list{
    margin: 40px 40px 40px 30px;
}

.nav-list-item{
    margin-bottom: 20px;
    list-style: none;
    cursor: pointer;
}

.nav-list-item:hover{
    color: var(--color-primary-lighter);
}

.check-icon{
    color: green;
    font-size: 2rem;
    margin-left:15px !important
}
.close-icon{
    color: #B14000;
    font-size: 2rem;
}

.active-item{
    color: var(--color-primary-lighter);
}

.table-head{
    font-weight: bold;
    font-size: 1.8rem;
}

.user-table{
    margin-top: 20px;
}

.user-table-head{
    font-weight: bold;
}

.table-avatar{
    margin-right: 5px !important;
}

.user-mgmt-container{
    padding: 0px 55px 0px 0px;
}

.update-btn{
    margin: 0px 6px 0px 0px;
    color: #1D3948, !important;
    border-color: #1D3948 !important;
}

.update-btn:hover{
    background-color: #1D3948 !important;
    color: #fff !important;
    border-color: #fff !important;
}

.delete-btn{
    color: #B14000 !important;
    border-color: #B14000 !important;
}
.delete-btn:hover{
    background-color: #B14000 !important;
    color: #fff !important;
    border-color: #fff !important;
}

.ant-popover-buttons .ant-btn-primary {
    color: #fff;
    background: #1D3948 !important;
    border-color: #FFFFFF;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-row{
    cursor: pointer;
    display: flex;
}

.ant-message .anticon {
    
    color: black !important;
    
}

.contain-buttons{
    display: flex;
    align-items: center;
    align-content: space-between;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;