.container {
  background-color: var(--color-secondary-dark);
  width: 100vw !important;
  min-height: 100vh !important;
  display: flex;
  flex-direction: row;
  color: @primary-color;
}

.container aside {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: @primary-color;
  background-color: #1D3948;
}

.branding {
  display: flex;
  // padding: 20% 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-btn-microsoft{
  font-family: 'Lato', sans-serif;
  // margin: 5px;
  width: 100% !important;
  background-color: #7cbb00 !important;
  border-color: #7cbb00 !important;
  color: #fff !important;
  height: 42px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
}

.login-contain{
  width:100%;
  margin-top: 10px;
}

.login-btn-microsoft:hover{
  /* margin:5px; */
  background-color: #7dbb00ad !important;
  border-color: #7cbb00 !important;
  color: #fff !important;
}

.branding>h6 {
  color: @primary-color;
  font-size: 1.25em;
  margin: 10% 0%;
}

.form-container {
  display: flex;
  // flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10%;
}

.form-container button {
  border-radius: 6px;
  padding: 10px 5px !important;
  height: auto !important;
  width: 100%;
  font-size: 1em;
  background-color: #ea4335;
  border-width: 0px;
  color: #fff;
}

.form-container button:hover {
  background-color: #ea4435a6;
  border-width: 0px;
  // margin-bottom: 10px;
  // color: #000;
}

.branding-images {

  width: 90%;
  display: flex;
  justify-self: center;
}

.the-room-logo {
  width: 280px;
}

.image-container {
  display: none;
}

@media (min-width: 1100px) {
  aside {
    flex: 0;
    max-width: 35%;
  }

  .image-container {
    display: flex;
    flex: 1;
    background-color: var(--color-background) !important;
    background: url('../../assets/branding/banner.svg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}


.onboarding-con {
  height: 100vh;
  background-color: #F8FAFB;
  padding: 40px;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;