#reviews-root {
  background-color: var(--color-background);
  width: 100% !important;
  min-height: 90vh !important;
  display: flex;
  flex-direction: column;

  @media (max-width: 961px) {
    padding-top: 50px;
  }


}

.pending-rev {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 50px;
  margin-bottom: 20px;
  color: #1E7D79;

  @media (max-width: 961px) {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.review-table {
  margin: 5px 0;
}

.rev-table-contain {
  margin-left: 50px;
  margin-bottom: 20px;
  @media (max-width: 941px) {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.empty-review {
  margin: 50px;

  @media (max-width: 941px) {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.rev-table-label {
  color: #7C8DA9, 100%;
  font-size: 1.3rem;
  margin: 20px 0 10px 0;
}

.completed-review-options {
  display: flex;
}

.completed-q {
  background-color: #304D12 !important;
  color: #fff !important;
  border: #fff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: not-allowed !important;

  @media (max-width: 961px) {
    width: auto;
    font-size: 0.9rem !important;
  }
}

.edit-q-mobile {
  font-size: 0.9rem !important;
  font-weight: bold;
}

.btn-tick-icon {
  font-size: 1.7rem !important;
  font-weight: 800 !important;
  margin-right: 4px !important;
}

.rev-msg {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 50px;
  margin-bottom: 20px;
  color: #1E7D79;

  @media (max-width: 961px) {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.rev-next-steps {
  font-weight: normal;
}

.table-text-mobile {

  @media (max-width: 961px) {
    color: #1D3948;
    font-size: 1.2rem;
  }
}

// table data
thead {
  @media (max-width: 961px) {
    display: none;
  }
}

td {
  @media (max-width: 961px) {
    border-bottom: none !important;
  }
}

// alternate colors
.table-row-light {
  background-color: var(--color-secondary);
}

.table-row-dark {
  background-color: none;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;