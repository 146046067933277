// @page { size: landscape; }

.staff-review-con {
    display: flex;
    margin-top: 20px;
}

.emp-details-con {
    width: 75%;
    height: 75vh;
    overflow-y: scroll;

}

.emp-review-con {
    width: 25%;
    height: 75vh;
    border-left: 0.5px solid #BDBDBD;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 11;
    background-color: #fff;
}

.table-row-selected {
    background-color: #F8FAFB;
}

.print-header{
    height: 100px;
    width: 100%;
    border-bottom: var(--color-primary) 2px solid;
}

.compare-review-print {
    margin: 30px auto;
    width: 90%;
}

.printable-root {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.fixed-timeline {
    position: fixed;
    background-color: #fff;
    max-width: 69%;
    z-index: 10;
}

.survey-select.survey-select-staff-reviews {
    margin: 10px 0 !important;
    width: 100% !important;
}

.review-not-found {
    margin-top: '20px';
    font-size: '24px';
    font-weight: bold;
    margin: auto;
    text-align: center;
}

.row-cursor {
    cursor: pointer;
}

.review-summary-admin-tab {
    padding-right: 30px;
}



.q-rate-manager-report .ant-rate-star {
    font-size: 3rem !important;
    color: var(--color-primary) !important;
}

.q-rate-emp-res .ant-rate-star {
    font-size: 1.5rem !important;
    color: var(--color-primary) !important;
}

.rating-text-manager {
    font-weight: bold;
    font-size: 16px;
    margin-left: 16px;
    width: 24px;
}

.title-collpase-header .ant-collapse-header {
    cursor: auto !important;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;