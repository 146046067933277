#home {
  background-color: var(--color-background);
  width: 100% !important;
  height: 90vh !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media (max-width: 961px) {
    width: 100vw;
    padding: 0;
  }
}

.home-card {
  width: 25%;
  height: 145px;
  background: #F8FAFB;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 961px) {
    width: 32% !important;
    height: 80px;
    margin: 0;
    padding: 7px 8px;
  }
}

.home-card-manager {
  @media (max-width: 961px) {
    display: none !important;
  }
}

.home-card-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}


.home-card-head {
  color: #1D3948;
  font-weight: bold;
  font-size: 1.7rem;
  margin-bottom: 7px;

  @media (max-width: 961px) {
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.home-card-role {
  color: #1D3948;
  font-size: 1rem;
}

.home-card-name {
  color: #1D3948;
  font-size: 1rem;
  font-weight: bold;
}

.home-card-avatar {
  display: flex;
  align-items: center;
}

.home-card-info {
  display: flex;
  justify-content: space-between;
}

.deliverable-flex {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 50px;
  margin-bottom: 20px;
  color: #1E7D79;
  display: flex;
  justify-content: space-between;
}
.big-num {
  color: #1E7D79;
  font-size: 4rem;

  @media (max-width: 961px) {
    font-size: 3rem;
  }
}

.card-text {
  color: #1E7D79;
  font-size: 2rem;

  @media (max-width: 961px) {
    font-size: 1.5rem;
  }
}

.home-card-icon {
  @media (max-width: 961px) {
    width: 12px;
  }
}

.under-card {
  margin-top: 15px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 961px) {
    display: block;
  }
}

.con-glows {
  color: #1D3948;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 3px;

  @media (max-width: 961px) {
    font-size: 0.9rem;
  }
}

.total-rev {
  color: #1E7D79;
  font-size: 1.3rem;
  font-weight: bold;
}

.timeline-btn {
  color: rgb(152, 0, 6) !important;
  border: none !important;
  margin-left: 5px;
}

.view-btn {
  background-color: #1E7D79 !important;
  color: #fff !important;

  @media (max-width: 961px) {
    width: auto;
    font-size: 0.9rem !important;
  }
}

.tags-flex {
  display: flex;

  @media (max-width: 961px) {
    font-size: 0.9rem;
    display: inline-block;
  }
}

.tags {
  font-size: 1rem;
  background-color: #304D12;
  color: #fff;
  padding: 6px;
  margin: 3px 2px;
  min-width: 90px;
  text-align: center;

  @media (max-width: 961px) {
    float: left;
  }
}

.red {
  background-color: #B14000;
}

.rev-timeline-header {
  display: none;

  @media (max-width: 961px) {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.rev-timeline-title {
  color: #1D3948;
  font-weight: bold;
  font-size: 1.5rem;
}

.rev-timeline {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
}

.circle {
  background: #F8FAFB;
  border-radius: 50%;
  min-width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.between-line {
  // width: 220px;
}

.between-texts {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
}

.timeline-text {
  color: #1D3948;
  font-size: 1.3rem;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (max-width: 961px) {
    font-weight: bold;
  }
}

.rev-timeline-mobile {
  display: inline-flex;
  width: 100%;
  overflow-x: auto;

}

.rev-timeline-mobile-card {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rev-timeline-mobile-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rev-timeline-mobile-name {
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 16px;
}

.rev-timeline-mobile-role {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 14px;
}

.rev-timeline-mobile-nextreview {
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 14px;
}

.rev-timeline-mobile-time {
  font-size: 1.1rem;
  font-weight: normal;
}

.rev-timeline-mobile-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.home-root {
  margin: 40px 0 0 0;
}

.reviews-root {
  margin-top: 50px;
  margin-left: -50px !important;

  @media (max-width: 961px) {
    margin-top: auto;
    margin-left: 0px !important;
  }
}

.hide-on-mobile {
  display: block;

  @media (max-width: 961px) {
    display: none;
  }
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;