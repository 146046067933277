.review-drawer-root{
    display: flex;
    flex-direction: column;
}

.selected-card{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: #1D3948 2px solid;
    color: #fff !important;
    background: #1E7D79 !important;
}

.selected-rev-card-name{
    color: #f75d03 !important;
}
.selected-rev-card-role {
    color: #fff !important;
}

.review-card{
    display: flex;
    flex-direction: column;
    // height: 130px;
    // width: 300px;
    border-radius: 0px;
    background: #F8FAFB;
    padding: 15px;
    margin: 0 10px 0 0px;
    cursor: pointer;
}

.rev-card-top{
    display: flex;
    justify-content: flex-end;
}
.rev-card-bottom{
    display: flex;
    justify-content: flex-end;
}

.rev-card-row{
    display: flex;
margin: auto;
justify-content: flex-start;
width: 90%;
background-color: #fff;
height: 191px;
position: fixed;
top: 0;
z-index: 10;
}

.rev-card-tag{
    background-color: var(--color-primary);
    padding: 5px;
    color: #fff;
    font-size: 1rem;
    margin: 6px 0 0 0;
    min-width: 70px;
    text-align: center;
}

.rev-card-role{
    font-size: 1.1rem;
    color: var(--color-primary);
}
.rev-card-name{
    color: var(--color-primary);
    font-weight: bold;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rev-text-col{
    width: 70%;
}

.header{
    font-size: 1.6rem;
    color: #B14000 !important;
    font-weight: bold;
}

.header-res{
    font-size: 1.6rem;
    color: #B14000 !important;
    font-weight: bold;
    margin-top:14px
}

.rev-card-profile{
    display: flex;
    align-items: center;
}

.rev-card-avatar{
    width: 50px !important;
    height: 50px !important;
    margin: 0 12px 0 0 !important;
}

.rev-accordion{
    width: 85%;
    margin:  50px auto !important;
}

.next-survey-btn{
    background-color: #1D3948 !important;
    margin: 17px auto !important;
    color: #fff !important;
    width: 120px !important;
    border: none !important;
}

.rev-accordion .ant-collapse-header{
    background-color: #7C8DA9 !important;
    color: #fff !important;
    font-size: 1.5rem !important;
    font-weight: bold;
}

.survey-response-area{
    margin: 60px auto;
    margin-top: 170px;
    width: 84% !important;
}

.completed-check{
    font-size: 2rem !important;
    color: #304D12 !important;
}

.completed-text{
    color: #304D12 !important;
}

.non-complete{
    font-size: 1.8rem !important;
    font-weight: 700 !important;
    color: #1D3948 !important;
    font-weight: bolder;
}

.completed-rev{
    opacity: 0.7;
    cursor: not-allowed;
}

.survey-completed-view{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.no-del-view{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top:15%;
}

.completed-img{
    margin: 30px;
    width: 50%;
}
.no-del-img{
    margin: 30px;
    width: 20%;
}

.submit-rev-btn{
    background-color: #1E7D79 !important;
    color: #fff !important;
}

.thanks-text{
    font-weight: bold;
    font-size: 1.5rem;
    color: #1D3948;
}

.carousel{
    width: 85%;
    z-index: 2;
    position: fixed;
    left: 9%;
    top: 5%;
    top: 5;
    margin: auto;
    background-color: #fff;
}

.del-drawer{
    margin-top:40px;
   margin-left: 0px !important;
}

.info{
    font-size: 1.6rem;
    margin-bottom: 20px;
    font-weight: bold;
}


.compare-table{
    margin-top: 24px !important;

    @media (max-width: 992px) {
        .carousel{
            width: 85%;
            margin: 80px auto 0px auto;
        }
    }
}

.react-multi-carousel-item{
    margin-right: 10px;
    // width: unset !important;
}

@media (max-width: 992px) {

    .carousel{
        width: 85%;
        margin: 80px auto 0px auto;
    }
}
@media (max-width: 420px) {
    .review-card{
        width: 100%;
    }
}

.chart-report-tabs{
    @media (max-width: 961px) {
        margin-top: 80px !important;
    }
}

.rate-contain{
    @media (max-width: 961px) {
        flex-direction: column;
    }
}

.launch-date{
    font-weight: bold;
    color: #1D3948;
    font-size:2rem;
}

.review-instructions{
    margin-top: 16px;
    margin-bottom: 16px;
}
.final-rating{
    margin-top: 10px;
}

.ant-tabs-tab .ant-tabs-tab-active {
    color: #000 !important; 
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
    color: #000 !important; 
    font-weight: bold !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
    border-bottom: 2px solid #000;
}

@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;