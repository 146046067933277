#organisations {
    background-color: var(--color-background);
    width: 100% !important;
    height: 90vh !important;
    overflow-y: auto;
    padding: 40px;
}

.create-review-schedule-card {
    display: block;
    width: 196px !important;
    height: 161px !important;
    background-color: #F8FAFB !important;
    float: left;
    margin-top: 2em !important;
    margin-left: 2em !important;
    border-radius: 16px !important;
    // box-shadow: 3px 20px 20px rgba(192, 192, 192, 0.357681);
}

.create-review-schedule-card .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.review-schedule-card {
    display: block;
    width: 196px !important;
    height: 161px !important;
    border-radius: 16px !important;
    background-color: #fff;
    float: left;
    margin-left: 2em !important;
    margin-top: 2em !important;
}


.review-schedule-card-text {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;