#admin-home {
  background-color: var(--color-background);
  width: 100% !important;
  height: 90vh !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.admin-card{
  width: 33%;
  height: 145px;
  background: #F8FAFB;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-start;
}

.card-text{
  display: flex;
  flex-direction: column;
  color: #1D3948, 100%;
}

.admin-card-flex{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.admin-card-head{
  color: #1D3948;
  font-weight: bold;
  font-size: 1.7rem;
  margin-bottom: 7px;
}

.admin-card-num{
  font-size: 3.3rem;
  color: #1D3948;
  font-weight: bold;
}

.doc-icon{
  margin-left: 30px;
}

.admin-left{
  display: flex;
  flex-direction: column;
  width: 72%;
  margin-right: 4px;
}

.admin-right{
  display: flex;
  flex-direction: column;
  width: 28%;
  margin-left: 4px;
}

.flex-row{
  display: flex;
}

.recently-logged-contain{
  height: 52vh;
  background: #F8FAFB;
  margin-top: 20px;
  overflow: scroll;
 
  // width: 29%;
}

.admin-graph-container{
  // width: 75%;
  background: #F8FAFB;
  height: 60vh;
  width: calc(100% - 10px);
}

.graph-head{
  padding: 20px;
  border-bottom: 0.5px solid rgba(208, 208, 208, 0.7);
  display: flex;
  justify-content: space-between;
}

.graph-title{
  font-size: 1.6rem;
  font-weight: bold;
}

.graph-dropdown{
  font-size: 1.1rem;
}

.recently-logged-head{
  padding: 20px;
  border-bottom: 0.5px solid rgba(208, 208, 208, 0.7);
  display: flex;
  justify-content: space-between;
}

.recent-title{
  font-size: 1.4rem;
  color: #1D3948;
  font-weight: bold;
}

.recent-list-title{
  font-size: 1.1rem;
  color: #1D3948;
  font-weight: bold;
  line-height: 18px;
}

.recent-list-time{
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  color: #000;
}

.recent-list-role{
  padding-top: 10px;
  font-size: 1rem;
  color: #1E7D79;
  font-weight: 600;
  line-height: 10px;
}


.recent-list-survey-name{
  font-size: 1rem;
  color: #000;
  line-height: 11px;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;