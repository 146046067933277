#survey-root {
    background-color: var(--color-background);
    // width: 100vw !important;
    min-height: 90vh !important;
    display: flex;
}

.question-type-list{
  display: flex;
  flex-direction: column;
  border-right: 1px solid #afafaf;
  margin-right: 40px;
  width: 20%;
  align-items: center;
}

.survey-section{
  display: flex;
  flex-direction: column;
}

.survey-droppable-area{
  display: flex;
  flex-direction: column;
}

.add-question-text{
  color: var(--color-primary);
  margin-top: 40px;
  margin-bottom: 25px;
  
}

.question-title{
  max-width: 80%;
}

.question-type-box{
  width: 66px;
  height: 66px;
  border-radius: 10px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 ;
}

.cb-question .ant-checkbox-group-item  {
  border-radius: 12px !important;
  background-color: #1D394862 !important;
  padding: 8px !important;
  color: #fff !important;
  margin: 4px;
}

.q-type-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.type-name{
  font-size: 1rem;
}

.survey-section{
  // background-color: gray;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-droppable-area{
  width: 95%;
  min-height: 70%;
  overflow-y: auto;
}

.survey-controls{
  width: 95%;
  display: flex;
  justify-content: space-between;
  border-bottom: gray solid 1px;
  align-items: center;
}

.survey-select{
  width: 40% !important;
  margin: 50px 0px  !important;
  background-color: transparent !important;
}

.survey-select  .ant-select-selector{
  background-color: #F8FAFB!important;
  border: none !important;
}

.question{
  display: flex;
  flex-direction: column;
  border-bottom: gray solid 1px;
  padding: 30px 0px;
}

.question:hover{
  background-color: #fafafa;
}

.items-head{
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-opt{
  margin-right: 10px;
}

.add-item-btn{
  background-color: var(--color-primary) !important;
  color:  #fff !important;
  height: 25px !important;
  width: 25px !important;
  font-size: 0.8rem;
  border: none !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius: 50% !important;
  margin-left: 10px;
}

.question-top{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  color: var(--color-primary);
}

.question-controls{
  color: #1E7D79;
}

.q-rate .ant-rate-star{
  font-size: 4rem !important;
  color: var(--color-primary) !important;
}

.edit-q{
  margin-right: 5px;
  color: #1E7D79 !important;
  border: none !important;
}

.delete-q{

  color: #1E7D79 !important;
  margin-right: 5px;
  border: none !important;
}

.ant-radio-inner::after{
  background-color: var(--color-primary) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--color-primary) !important;
}

.ant-radio:hover .ant-radio-inner{
  border-color: var(--color-primary) !important;
}

.multi-choice{
  display: flex !important;
  flex-direction: column;
  margin-top: -20px !important;
}

.new-survey-btn{
  background-color: var(--color-primary) !important;
  color: #fff !important;
  border-color: #fff !important;
}

.new-survey-btn:hover{
  background-color: #1D3948c7 !important;
}

.welcome-closing-messsage{
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}

.ant-checkbox-inner{
  border: 1px solid #d9d9d9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.opening-msg{
  font-weight: bold;
  font-size: 1.8rem;
  color: var(--color-primary);
}

.self-rev-cb{
  margin: 10px 0px !important;
}
@primary-color: #FFFFFF;@primary-1: #F8FAFB;@primary-2: #D7C499;@primary-3: #1D3948;@primary-4: #1E7D79;